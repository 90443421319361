import React from 'react'

const Banner = ({image, text}) => {
  return (
    <section className='px-4 py-12 lg:py-48 border-b-12 lg:border-b-36 border-primary overflow-hidden relative'>
      <div className='absolute bottom-0 right-0 w-full h-full'>
        {image}
      </div>
      <div className='w-full max-w-screen-xl mx-auto'>
        <div className='flex justify-end relative z-10'>
          <div className='w-full lg:w-1/2'>
            <h3 className='text-base lg:text-2xl border-l-4 border-secondary pl-6'>
              {text}
            </h3>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
