import React, { useState, useEffect } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha";

const ContactSection = () => {
  const { t } = useTranslation()

  const recaptchaRef = React.createRef();
  const [submitted, setSubmitted] = useState(false)

  const [fieldErrors, setFieldErrors] = useState({})
  const [inputs, setInputs] = useState({
    contact: '',
    message: ''
  });
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });

  const validationRules = {
    contact: val => !!val,
    message: val => !!val,
  }

  const validate = () => {
    let errors = {};
    let hasErrors = false;
    for (let key of Object.keys(inputs)) {
      errors[key] = !validationRules[key](inputs[key])
      hasErrors |= errors[key]
    }
    setFieldErrors(prev => ({ ...prev, ...errors }))
    return !hasErrors
  }

  const renderFieldError = field => {
    if (fieldErrors[field]) {
      return <div className='text-red text-sm mt-2'>Please enter a valid {field}.</div>
    }
  };
  useEffect(() => {
    if (Object.keys(fieldErrors).length > 0) {
      validate();
    }
  }, [inputs]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = event => {
    event.persist();
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      setFieldErrors({})
      setInputs({
        contact: '',
        message: ''
      });
    }
  };
  const handleOnSubmit = event => {
    event.preventDefault()
    // const recaptchaValue = recaptchaRef.current.getValue();
    // this.props.onSubmit(recaptchaValue);
    if (!validate()) {
      return;
    }
    setServerState({ submitting: true })
    axios({
      method: 'POST',
      url: 'https://formspree.io/xleazzvo',
      data: inputs
    })
      .then(r => {
        handleServerResponse(true, "Dziękujemy za wiadomość.")
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error)
      });
  }

  const onChangeRecaptcha = () => {
    setSubmitted(true)
  }

  return (
    <section id='contactSection' className='px-4 py-16'>
      <div className='w-full max-w-screen-xl mx-auto'>
        <h3 className='text-primary text-lg uppercase font-bold has-decorator mb-10'>{t('contactSection.header')}</h3>
        <div className='flex flex-wrap -mx-3'>
          <div className='flex flex-wrap w-full px-4 -mx-4 lg:w-2/3'>
            <div className='w-1/2 px-3'>
              <div className='font-light mb-4 text-xs sm:text-base'>
                {t('contactSection.address.line01')} <br />
                {t('contactSection.address.line02')}  <br />
                {t('contactSection.address.line03')}  <br />
                {t('contactSection.address.line04')}
              </div>
              <div className='text-xs sm:text-base'>
                <div><b>{t('contactSection.nipTitle')} </b> {t('contactSection.nipValue')}</div>
                <div><b>{t('contactSection.regonTitle')} </b> {t('contactSection.regonValue')}</div>
                <div><b>{t('contactSection.bdoTitle')} </b> {t('contactSection.bdoValue')}</div>
              </div>
            </div>
            <div className='w-1/2 px-3'>
              <div className='mb-3 sm:mb-5 text-xs sm:text-base'>
                <b className='block'>{t('contactSection.persons.person01.name')}</b>
                <a href={`tel:${t('contactSection.persons.person01.phoneClickable')}`} className='font-light'>{t('contactSection.persons.person01.phone')}</a>
              </div>
              <div className='mb-3 sm:mb-5 text-xs sm:text-base'>
                <b className='block'>{t('contactSection.persons.person02.name')}</b>
                <a href={`tel:${t('contactSection.persons.person01.phoneClickable')}`} className='font-light'>{t('contactSection.persons.person02.phone')}</a>
              </div>
              <div className='mb-3 sm:mb-5 text-xs sm:text-base'>
                <b className='block'>{t('contactSection.persons.person03.name')}</b>
                <a href={`tel:${t('contactSection.persons.person01.phoneClickable')}`} className='font-light'>{t('contactSection.persons.person03.phone')}</a>
              </div>
              <div className='working-hours font-light text-xs sm:text-base'>
                {t('contactSection.officeNote')}
                {` `}
                <br className='hidden sm:block' />
                {t('contactSection.officeNoteWorkingHours')}
              </div>
            </div>
          </div>
          <div className='w-full lg:w-1/3 mx-4'>
            <h3 className='font-medium uppercase text-sm md:text-xl mb-4 mt-10 md:mt-0'>{t('contactSection.contactFormHeader')}</h3>
            <form onSubmit={handleOnSubmit} className='m-0'>
              <div className='mb-4'>
                <input
                  id='contact'
                  name='contact'
                  type='text'
                  required
                  onChange={handleOnChange}
                  value={inputs.contact}
                  placeholder={t('contactSection.contactFormContactDetailsPlaceholder')}
                  className="w-full"
                />
                {renderFieldError('contact')}
              </div>

              <div className='mb-4'>
                <textarea
                  id='message'
                  name='message'
                  rows='8'
                  required
                  onChange={handleOnChange}
                  value={inputs.message}
                  placeholder={t('contactSection.contactFormContactMessagePlaceholder')}
                  className="w-full"
                />
                {renderFieldError('message')}

              </div>
              <div className='flex flex-wrap items-end justify-between'>
                <div className='flex-shrink-0 mb-1'>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    sitekey="6LcFrtAeAAAAADrL-gU1hHAi-KUGaJ7R-PQ6CMhH"
                    onChange={onChangeRecaptcha}
                  />
                </div>
                <div className='mb-1 pb-1 mt-4 md:mt-0'>
                  <button
                    disabled={!submitted}
                    type='submit'
                    className='button button-narrow button--secondary'
                    onClick={() => setSubmitted(true)}
                  >
                    {t('contactSection.sendButton')}
                  </button>
                </div>
              </div>
              {serverState.status && (
                <div className='mt-4'>
                  <div className={`bg-green-40 p-3 text-center text-xs mt-6 font-normal ${!serverState.status.ok ? 'bg-red-40' : 'bg-green-40'}`}>
                    {serverState.status.msg}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactSection
